import Hero from "../components/Hero"
import Myclients from "../components/Myclients"

function Home(){

return(
    <>
   <Hero/>
   {/* <Myclients/> */}
    </>
)

}


export default Home