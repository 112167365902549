import React from 'react'
import LOGO from '../assets/logosvg.svg'
import PHOTO from '../assets/photo.jpg'
import UPWORK from '../assets/upwork.svg'
import EMAIL from '../assets/email2.svg'
import LINKEDIN from '../assets/linkedin.svg'
import { motion } from "framer-motion"
// import WP from '../assets/icons/wp.svg'
// import CSS from '../assets/icons/css.svg'
// import HTML from '../assets/icons/html.svg'
// import REDUX from '../assets/icons/redux.svg'

const Hero = () => {
  return (
    <div className= ' bg-black py-8 min-h-[100vh] flex flex-col justify-center items-center bg-no-repeat sm:bg-center overflow-hidden border bg-bottom' style={{backgroundImage:`url('/bg2.png')`, alpha:'0.5'}}>
      
      
      <div className='flex flex-col  '>
        <div className='flex justify-start flex-col sm:flex-row '> 

        <motion.div initial={{ opacity: 0,  y:-150 }}
    animate={{ opacity: 1, y:0 }}
    transition={{ duration: 1, delay:1, }} >
        <img src={LOGO} width={100} alt='logo' className='ml-5 sm:ml-6 '/>
        </motion.div>
    
        <div className='flex flex-col sm:flex-grow '> 

      <h1 className=' text-white my-2 sm:text-6xl  text-4xl font-bold  ml-6 sm:ml-5 uppercase  '> 
      <motion.div initial={{ opacity: 0,  y:50 }}
    animate={{ opacity: 1, scale: 1, y:0 }}
    transition={{ duration: 1, delay:1, }} >
        <span class="bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-violet-500">
    Haroon Kiani 
  </span>
  </motion.div>

      </h1>
      <motion.div initial={{ opacity: 0,  y:50 }}
    animate={{ opacity: 1, scale: 1, y:0 }}
    transition={{ duration: 1, delay:1.5, }} >
<h2 className='text-white mt-2 mb-10 font-light  uppercase ml-6 sm:ml-5 text-sm'> Web Design & Development | Graphics Design | Marketing</h2>     
</motion.div>
</div>
<motion.div initial={{ opacity: 0,  x:150 }}
    animate={{ opacity: 1, scale: 1, x:0 }}
    transition={{ duration: 1, delay:2, }} >
        <img src={PHOTO} width={150} alt='logo' className=' ml-6 sm:ml-5 rounded-2xl mr-8'/>
        </motion.div>
        </div>



<div className='flex sm:w-[1080px] w-[390px] items-start sm:flex-row flex-col mt-10'>
<motion.div initial={{ opacity: 0,  y:50 }}
    animate={{ opacity: 1, scale: 1, y:0 }}
    transition={{ duration: 1, delay:2.2, }} >

      <a href='mailto:haroon@haroonkiani.co.uk'>
<div className='bg-slate-100 bg-opacity-20 border border-white border-opacity-20 flex items-center hover:bg-pink-400 hover:bg-opacity-50 py-2 px-4 m-4 sm:ml-5 rounded-2xl sm:w-[320px] w-[350px] '>

<img src={EMAIL} width={40} alt='upwork' className='mr-4'/>

      <h2 className='text-white my-4 font-thin tracking-wide text-1xl text-base'>
          haroon@haroonkiani.co.uk
      </h2>
      </div>
        </a>
        </motion.div>

        <motion.div initial={{ opacity: 0,  y:50 }}
    animate={{ opacity: 1, scale: 1, y:0 }}
    transition={{ duration: 1, delay:2.4, }} >
        <a href='https://www.upwork.com/freelancers/haroonkiani' target='_blank' rel='noreferrer' className='text-white'> 
      <div className='bg-slate-200 bg-opacity-10 border border-white border-opacity-20 flex items-center  hover:bg-pink-400 hover:bg-opacity-50 py-2 px-4 m-4 rounded-2xl sm:w-[320px] w-[350px] '>

        <img src={UPWORK} width={100} alt='upwork' className='mr-4'/>
      <h2 className='text-white my-4 font-thin  tracking-wide text-1xl flex '>
        I'M on UpWork
        
      </h2>
</div>
        </a>
        </motion.div>
        <motion.div initial={{ opacity: 0,  y:50 }}
    animate={{ opacity: 1, scale: 1, y:0 }}
    transition={{ duration: 1, delay:2.6, }} >

        <a href='https://www.linkedin.com/in/haroon-kiani/' target='_blank' rel='noreferrer' className='text-white'> 
<div className='bg-slate-200 bg-opacity-10 border-white border border-opacity-20 flex items-center  hover:bg-pink-400 hover:bg-opacity-50 py-2 px-4 m-4 rounded-2xl sm:w-[320px] w-[350px] '>

        <img src={LINKEDIN} width={40} alt='upwork' className='mr-4'/>

      <h2 className='text-white my-4 font-thin  tracking-wide text-1xl'>
        
        LinkedIn
      </h2>
      </div>
      </a>
      </motion.div>
      </div>

      </div>
{/* <div className='rounded-lg ml-8 flex bg-gray-800 grow p-8 justify-between flex-col'> 
    <h3> Tech Stack</h3>
    <img src={WP}  width={50} alt='icon-1' />
    <img src={HTML}  width={50} alt='icon-1' />
    <img src={CSS}  width={50} alt='icon-1' />
    <img src={REDUX}  width={50} alt='icon-1' />
    </div> */}
    

    
    
    </div>
  )
}

export default Hero
